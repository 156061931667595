<template>
  <div class="flix-form-group flix-checkin-buttons">
    <a href="#" @click.prevent="setCallback('checkIn')" class="flix-html-a flix-text-success">{{ $t('message.edit', {name: $tc('message.checkIn', 2) }) }}</a>
    <a href="#" @click.prevent="setCallback('checkOut')" class="flix-html-a flix-text-danger">{{ $t('message.edit', {name: $tc('message.checkOut', 2) }) }}</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    open: [Boolean, String]
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setCallback (ret) {
      if (ret === this.open) {
        this.callback(false)
        return false
      }
      this.callback(ret)
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-checkin-buttons
    display: flex
    gap: 10px
    justify-content: center
</style>
